

















































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as appRegion from "@/libs/app_region";

import { MessageBoxInputData } from "element-ui/types/message-box";

@Component
export default class Merchants extends Vue {
  pagerHeader: any = {
    title: "商家列表",
    desc: "商家列表",
    breadcrumb: ["商家管理", "商家列表"],
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    categoryId: "",
    keywords: "",
    startDate: null,
    endDate: null,
    agentId: "",
    provinceCode: "",
    cityCode: "",
    areaCode: "",
    sortField: null,
    sortDirection: null,
    isActive: null,
  };
  tableData: any = {
    items: [],
    totalCount: 0,
  };
  categories: any[] = [];
  dateRange: any[] = [];
  readerAgentOptions: any[] = [];
  agentSearchLoading: boolean = false; //搜索代理 出现loading
  agentItems: any[] = [];
  provinceOptions: any[] = [];
  cityOptions: any[] = [];
  countyOptions: any[] = [];
  MerchantAgentArr: any[] = []; //存多选的商户id  数组
  dialogFormVisible1: any = {
    show: false,
    form: {
      agentId: "",
    },
    rules: {
      agentId: [{ required: true, message: "请输入代理", trigger: "change" }]
    },
    loading: false,
  };
  @Watch("filter.provinceCode")
  provinceCodeChange(provinceCode: string) {
    this.filter.cityCode = "";
    this.filter.areaCode = "";
    this.cityOptions = appRegion.getCitys(provinceCode);
    this.countyOptions = [];
  }
  @Watch("filter.cityCode")
  cityCodeChange(cityCode: string) {
    this.filter.areaCode = "";
    this.countyOptions = appRegion.getCountys(cityCode);
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  // 打开修改代理弹窗
  changeAgent() {
    if (this.MerchantAgentArr.length > 0) {
      this.dialogFormVisible1.show = true;
      this.dialogFormVisible1.form.agentId = "";
    } else {
      this.$notify.error("请选择商户");
    }
  }
  // 多选
  handleSelectionChange(val: any) {
    this.MerchantAgentArr = val;
  }
  // 搜索代理
  async readerMemberOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query,
        },
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  // 修改代理弹出框 点击确定
 async submit() {
   if (this.MerchantAgentArr.length == 0)
      return void this.$message.error("请选择商户");
    var deviceIds: any[] = [];
    this.MerchantAgentArr.forEach((item) => {
      deviceIds.push(item.id);
    });
   (this.$refs.roleForm as any).validate(async (valid: boolean) => {
      if (valid) {
          this.dialogFormVisible1.loading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Merchant/SetAgent",
        {
          ids: deviceIds,
          agentId: this.dialogFormVisible1.form.agentId
        }
      );
      if (res.data.success) {
        this.$message.success("修改成功");
        this.getData();
        this.dialogFormVisible1.show = false;
        this.dialogFormVisible1.loading = false;
      }
    } catch (error) {
      console.log(error);
      this.dialogFormVisible1.loading = false;
    }
      }
   })
  }

  //日期改变
  timeVoi(value: Date[]) {
    if (value != null && value.length == 2) {
      this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
      if (
        value[1].Format("hh") == "00" &&
        value[1].Format("mm") == "00" &&
        value[1].Format("ss") == "00"
      ) {
        this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
        this.dateRange = [
          (this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss")),
          (this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")),
        ];
      } else {
        this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
        this.dateRange = [
          (this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss")),
          (this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")),
        ];
      }
      this.getData();
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.getData();
    }
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get(
        "/api/services/app/Merchant/GetPagedList",
        {
          params: {
            ...this.filter,
          },
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async renderAgentOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query,
        },
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  //   activated() {
  //     this.getData();
  //   }
  created() {
    if (this.$route.query.isActive) {
      this.filter.isActive = Boolean(Number(this.$route.query.isActive));
    }

    // 根据参数知道是否需要根据时间搜索 从统计点击过来
    if (this.$route.query.time == "0") {
      this.getTime(0);
    } else if (this.$route.query.time == "1") {
      this.getTime(1);
    } else if (this.$route.query.time == "2") {
      this.getTime(7);
    } else if (this.$route.query.time == "3") {
      this.getTime(30);
    }
    this.filter.size = this.systemSettings.pageSize;
    this.getData();
    this.readerCategory();
    this.renderAgentOptions("");
    this.provinceOptions = appRegion.getProvinces();
  }

  // 处理传过来的时间数字
  getTime(days: any) {
    if (days == 1) {
      // @ts-ignore
      this.filter.startDate = new Date(
        // @ts-ignore
        new Date() - days * 24 * 3600 * 1000
      ).Format("yyyy-MM-dd 00:00:00");
      // @ts-ignore
      this.filter.endDate = new Date(
        // @ts-ignore
        new Date() - days * 24 * 3600 * 1000
      ).Format("yyyy-MM-dd 23:59:59");
      // @ts-ignore
      this.dateRange = [
        // @ts-ignore
        new Date(new Date() - days * 24 * 3600 * 1000).Format(
          "yyyy-MM-dd 00:00:00"
        ),
        // @ts-ignore
        new Date(new Date() - days * 24 * 3600 * 1000).Format(
          "yyyy-MM-dd 23:59:59"
        ),
      ];
    } else {
      // @ts-ignore
      this.filter.startDate = new Date(
        // @ts-ignore
        new Date() - days * 24 * 3600 * 1000
      ).Format("yyyy-MM-dd 00:00:00");
      this.filter.endDate = new Date().Format("yyyy-MM-dd 23:59:59");
      // @ts-ignore
      this.dateRange = [
        // @ts-ignore
        new Date(new Date() - days * 24 * 3600 * 1000).Format(
          "yyyy-MM-dd 00:00:00"
        ),
        new Date().Format("yyyy-MM-dd 23:59:59"),
      ];
    }
  }
  async readerCategory() {
    try {
      var res = await this.$ajax.get("/api/services/app/Category/GetAll", {
        params: {
          type: 2,
        },
      });
      this.categories = res.data.result;
    } catch (error) {}
  }

  async computeStatistics(id: number) {
    var loading = this.$loading({ text: "更新中" });
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Merchant/ComputeStatisticsInfo",
        {
          id,
        }
      );
      this.getData();
    } catch (error) {}
    loading.close();
  }
  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.getData();
  }
  async deleteMerchant(row: any) {
    var result = await this.$confirm(
      `确认要删除商户【${row.name}】吗？删除前请先回收设备`
    );
    if (result == "confirm") {
      var loading = this.$loading({
        text: "删除中...",
      });
      try {
        var res = await this.$ajax.delete("/api/services/app/Merchant/Delete", {
          params: {
            id: row.id,
          },
        });
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.getData();
        }
      } catch (error) {}
      loading.close();
    }
  }
  async switchActiveStatus(row: any) {
    var successed = false;
    var auditMessage = "";
    if (row.isActive) {
      var inputResult = (await this.$prompt(
        "请输入禁用原因"
      )) as MessageBoxInputData;
      if (inputResult.action == "confirm") {
        successed = false;
        auditMessage = inputResult.value;
        if (!auditMessage) return this.$notify.error("请输入原因");
      } else {
        return;
      }
    } else {
      var result = await this.$confirm("确认要启用改店吗");
      if (result == "confirm") {
        successed = true;
      } else {
        return;
      }
    }

    var loading = this.$loading({
      text: successed ? "启用中..." : "禁用中...",
    });
    try {
      var res = await this.$ajax.post("/api/services/app/Merchant/Audit", {
        successed,
        auditMessage,
        id: row.id,
      });
      if (res.data.success) {
        this.$notify.success(successed ? "启用成功" : "禁用成功");
        this.getData();
      }
    } catch (error) {}
    loading.close();
  }
}
